import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const ValuationService = {
  formatForm (data) {
    let formatedData = {}

    formatedData.motivo = data.motivo.id
    formatedData.origem = data.origem.id
    formatedData.descricao = data.descricao
    formatedData.situacao = 0
    formatedData.referenciaInterna = data.referencia_interna
    formatedData.valor = data.valor
    // formatedData.peso = data.motivo.peso_padrao

    formatedData.destinos = data.destinos.map(x => ({
      id: x.id,
      protagonista: x.protagonista
    }))

    return formatedData
  },

  /**
   * http://localhost/api/v2/valuation
   *
   * @param filter
   * @returns {Promise<any>}
   */
  list (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/valuation?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuation/{id}
   *
   * @param id
   * @returns {Promise<any>}
   */
  get (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/valuation/${id}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuation
   *
   * @param data
   * @returns {Promise<any>}
   */
  post (data) {
    // let formatedData = this.formatNewForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/valuation`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @param {int} id
   * @param {*} data
   * @returns valuation
   */
  put (id, data) {
    let formatedData = this.formatNewForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/valuation/${id}`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  delete (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/valuation/${id}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  getStatusList (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/valuation/status/list?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuation/allow
   *
   * @param data
   * @returns {Promise<any>}
   */
  postAllow (data) {
    // let formatedData = this.formatNewForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/valuation/allow`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuation/refuse
   *
   * @param data
   * @returns {Promise<any>}
   */
  postRefuse (data) {
    // let formatedData = this.formatNewForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/valuation/refuse`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  getComments (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/valuation/comment?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuation/comment
   *
   * @param data
   * @returns {Promise<any>}
   */
  postComment (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/valuation/comment`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuation/comment
   *
   * @param data
   * @returns {Promise<any>}
   */
  putComment (id, data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/valuation/comment/${id}`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  deleteLike (valuationId) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/valuation/${valuationId}/like`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  getFile (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/file/${id}`, {
          responseType: 'blob'
        }).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  deleteFile (valuationId, fileId) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/valuation/${valuationId}/file/${fileId}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}
export default ValuationService
