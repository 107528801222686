import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const ValuationReasonService = {
  formatForm (data) {
    let formatedData = {}

    formatedData.nome = data.nome
    formatedData.descricao = data.descricao
    formatedData.tipo = data.tipo.id
    formatedData.pesoPadrao = data.peso_padrao
    formatedData.possuiValor = data.possui_valor

    return formatedData
  },

  /**
   * http://localhost/api/v2/valuationreason
   *
   * @param filter
   * @returns {Promise<any>}
   */
  list (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/valuationreason?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuationreason/{id}
   *
   * @param id
   * @returns {Promise<any>}
   */
  get (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/valuationreason/${id}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuationreason
   *
   * @param data
   * @returns {Promise<any>}
   */
  post (data) {
    let formatedData = this.formatForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/valuationreason`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuationreason
   *
   * @param {int} id
   * @param {*} data
   * @returns valuation
   */
  put (id, data) {
    let formatedData = this.formatForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/valuationreason/${id}`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  delete (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/valuationreason/${id}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuationreason/options
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getReasonOptions (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/valuationreason/options?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/valuationreason/type/options
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getReasonTypeOptions (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/valuationreason/type/options?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}
export default ValuationReasonService
